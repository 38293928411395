<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  mounted(){
	// if(localStorage.getItem("navActive")){
	// 	this.$router.push({path:JSON.parse(localStorage.getItem("navActive")).activePath})
	// }
	
  }
};
</script>

<style>
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

#app {
  width: 100%;
  height: 100%;
  /* background-color: #f1f1f1; */
  overflow-y: scroll;
}
/* #app::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}
#app {
  -ms-overflow-style: none;
}
#app {
  overflow: -moz-scrollbars-none;
}
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
 -webkit-appearance: none;
  background: transparent;
} */
img {
  -webkit-user-drag: none;
}
</style>
