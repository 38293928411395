import Vue from 'vue'
import Router from 'vue-router'
import VueRouter from 'vue-router'


Vue.use(Router)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

// let router = new Router({
// 	routes:[]
// })

const sourceWarn = window.console.warn
window.console.warn  = function(...rest) {
	const args = Array.from(rest)
	
	if(args[0] && args[0].includes('Route with name') && args[0].includes('does not exist')) {
		setTimeout(()=>{
			router.push('/404')
		},0)
	}
	sourceWarn(...rest)
}

let pathList = []

let routes = [
	{
		path: "/404",
		name: "404",
		meta: {
			title: "404",
			keepAlive: false
		},
		component: () => import("@/views/error/404.vue")
	},
	{
		path: "/",
		name: "layout",
		redirect: {
			name: "home"
		},
		component: () => import("@/views/layout.vue"),
		children: [{
				path: "/home",
				name: "home",
				meta: {
					title: "首页"
				},
				component: () => import("@/views/home/home.vue")
			},
			// {
			// 	path: "/home1",
			// 	name: "home1",
			// 	meta: {
			// 		title: "首页1"
			// 	},
			// 	component: () => import("@/views/home/home1.vue")
			// },
			{
				"path":"/echartsMap",
				"name":"echartsMap",
				"meta":{
					title:"地图"
				},
				component:() => import("@/views/home/echartsMap.vue")
			},
			{
				"path":"/aboutUs/cooperation",
				"name":"cooperation",
				"meta":{
					title:"联系方式"
				},
				component:() => import("@/views/aboutUs/cooperation.vue")
			},
			{
				"path":"/news/:id",
				"name":"news",
				"meta":{
					title:"新闻"
				},
				component:() => import("@/views/news/news.vue")
			},
			{
				path:"/detail/:id",
				name:"detail",
				meta:{
					title:"列表"
				},
				component:() => import("@/views/detail/index.vue")
			},
			{
				path:"/chipProduct/:id",
				name:'chipProduct',
				meta:{
					title:'芯片产品'
				},
				component:() => import("@/views/detail/product.vue")
			},
			{
				path:"/smartproducts",
				name:'smartproducts',
				meta:{
					title:'智能产品'
				},
				component:() => import("@/views/smartproducts/smartproducts.vue")
			},
			{
				path:"/product/:id",
				name:'product',
				meta:{
					title:''
				},
				component:() => import("@/views/detail/productTable.vue")
			},
			{
				path:"/chairManager",
				name:"chairManager",
				meta:{
					title:'领军人物'
				},
				component:() => import("@/views/detail/chairManager.vue")
			},
			{
				path:"/teams",
				name:"teams",
				meta:{
					title:'领军人物'
				},
				component:() => import("@/views/teams/teams.vue")
			},
			{
				path:"/honors",
				name:"honors",
				meta:{
					title:'荣誉资质'
				},
				component:() => import("@/views/honors/honors.vue")
			},
			{
				path:"/detailInfo/:id/:navMenu/:navTitle/:navId",
				name:"detailInfo",
				meta:{
					title:"详情"
				},
				component:() => import("@/views/detail/detailInfo.vue")
			},
			{
				path:"/history",
				name:"history",
				meta:{
					title:"发展历程"
				},
				component:() => import("@/views/enterCompany/history.vue")
			},
			{
				path:"/recruiting",
				name:"recruiting",
				meta:{
					title:"人才招聘"
				},
				component:() => import("@/views/humanResources/recruiting.vue")
			},
			{
				path:"/positionDetail",
				name:"positionDetail",
				meta:{
					title:"岗位详情"
				},
				component:() => import("@/views/humanResources/positionDetail.vue")
			},
			{
				path:"/overView",
				name:"OverView",
				meta:{
					title:'企业介绍'
				},
				component:() => import("@/views/enterCompany/groupOverview.vue")
			},
			{
				path:"/groupDistribution",
				name:"GroupDistribution",
				meta:{
					title:'研究中心'
				},
				component:() => import("@/views/enterCompany/groupDistribution.vue")
			},
			{
				path:"/consult",
				name:"consult",
				meta:{
					title:'常见问答'
				},
				component:() => import("@/views/consult/index.vue")
			},
			{
				path:"/popular",
				name:"popular",
				meta:{
					title:'科普园地'
				},
				component:() => import("@/views/consult/popular.vue")
			},
			{
				path:"/satellite",
				name:"BDSallite",
				meta:{
					title:'北斗科普'
				},
				component:() => import("@/views/consult/satellite.vue")
			},
			{
				path:"/development",
				name:"development",
				meta:{
					title:'发展战略'
				},
				component:() => import("@/views/humanResources/development.vue")
			},
			{
				path:"/hardwareDesign",
				name:"hardwareDesign",
				meta:{
					title:'智能产品'
				},
				component:() => import("@/views/industry/hardwareDesign.vue")
			},
			{
				path:"/softwareDesign",
				name:"softwareDesign",
				meta:{
					title:'软件产品'
				},
				component:() => import("@/views/industry/softwareDesign.vue")
			},
			{
				path:"/smartSolution",
				name:"smartSolution",
				meta:{
					title:'应用场景'
				},
				component:() => import("@/views/industry/smartSolution.vue")
			},
			{
				path:"/productDetail",
				name:"productDetail",
				meta:{
					title:'智慧方案'
				},
				component:() => import("@/views/detail/productDetail.vue")
			},
			{
				path:"/softwareDesignDetail",
				name:"softwareDesignDetail",
				meta:{
					title:'智慧方案'
				},
				component:() => import("@/views/industry/softwareDesignDetail.vue")
			},
			{
				path:"/hardwareDesignDetail",
				name:"hardwareDesignDetail",
				meta:{
					title:'智慧方案'
				},
				component:() => import("@/views/industry/hardwareDesignDetail.vue")
			},
			{
				path:"/smartSolutionDetail",
				name:"smartSolutionDetail",
				meta:{
					title:'智慧方案'
				},
				component:() => import("@/views/industry/smartSolutionDetail.vue")
			},
			// {
			// 	path:"/industry/index",
			// 	name:"industry",
			// 	redirect:{name:"chipDesign"},
			// 	meta:{title:'集团产业'},
			// 	component:()=>import("@/views/industry/index.vue"),
			// 	children:[
			// 		{
			// 			path:"/industry/chipDesign",
			// 			name:"chipDesign",
			// 			meta:{title:'IC芯片设计'},
			// 			component:()=>import("@/views/industry/chipDesign.vue"),
			// 		},
			// 		{
			// 			path:"/industry/hardwareDesign",
			// 			name:"hardwareDesign",
			// 			meta:{title:'硬件设计'},
			// 			component:()=>import("@/views/industry/hardwareDesign.vue"),
			// 		},
			// 		{
			// 			path:"/industry/softwareDesign",
			// 			name:"softwareDesign",
			// 			meta:{title:'软件设计'},
			// 			component:()=>import("@/views/industry/softwareDesign.vue"),
			// 		},
			// 	]
			// },
			// {
			// 	path:'/socialResponsibility/index',
			// 	name:'socialResponsibility',
			// 	meta:{title:'社会责任'},
			// 	redirect:{name:"gloriousCause"},
			// 	component:()=>import("@/views/socialResponsibility/index.vue"),
			// 	children:[
			// 		{
			// 			path:'/socialResponsibility/gloriousCause',
			// 			name:'gloriousCause',
			// 			meta:{title:'光彩事业'},
			// 			component:()=>import("@/views/socialResponsibility/gloriousCause.vue")
			// 		},
			// 		{
			// 			path:'/socialResponsibility/socialGood',
			// 			name:'socialGood',
			// 			meta:{title:'社会公益'},
			// 			component:()=>import("@/views/socialResponsibility/socialGood.vue")
			// 		},
			// 		{
			// 			path:'/socialResponsibility/responsibilitySystem',
			// 			name:'responsibilitySystem',
			// 			meta:{title:'责任体系'},
			// 			component:()=>import("@/views/socialResponsibility/responsibilitySystem.vue")
			// 		},
			// 	]
			// },
			// {
			// 	path:'/socialResponsibility/socialDetail',
			// 	name:'socialDetail',
			// 	meta:{title:'详情内容'},
			// 	component:()=>import("@/views/socialResponsibility/socialDetail.vue")
			// },
			// {
			// 	path:'/news/index',
			// 	name:'news',
			// 	meta:{title:'新闻动态'},
			// 	redirect:{name:"companyNews"},
			// 	component:()=>import("@/views/news/index.vue"),
			// 	children:[
			// 		{
			// 			path:'/news/companyNews',
			// 			name:'companyNews',
			// 			meta:{title:'集团新闻'},
			// 			component:()=>import("@/views/news/companyNews.vue")
			// 		},
			// 		{
			// 			path:'/news/industryNews',
			// 			name:'industryNews',
			// 			meta:{title:'行业资讯'},
			// 			component:()=>import("@/views/news/industryNews.vue")
			// 		},
			// 		{
			// 			path:'/news/culturalEvents',
			// 			name:'culturalEvents',
			// 			meta:{title:'文化活动'},
			// 			component:()=>import("@/views/news/culturalEvents.vue")
			// 		},
			// 	]
			// },
			// {
			// 	path:"/news/newsDetail",
			// 	name:'newsDetail',
			// 	meta:{title:'新闻详情'},
			// 	component:() =>import("@/views/news/newsDetail.vue")
			// },
			// {
			// 	path:'/enterCompany/index',
			// 	name:"enterCompany",
			// 	mata:{title:'走进知码芯'},
			// 	redirect:{name:"chairmanSpeech"},
			// 	component:()=>import("@/views/enterCompany/index.vue"),
			// 	children:[
			// 		{
			// 			path:'/enterCompany/chairmanSpeech',
			// 			name:'chairmanSpeech',
			// 			meta:{title:'主席致辞'},
			// 			component:()=>import("@/views/enterCompany/chairmanSpeech.vue")
			// 		},
			// 		{
			// 			path:'/enterCompany/groupOverview',
			// 			name:'groupOverview',
			// 			meta:{title:'集团概况'},
			// 			component:()=>import("@/views/enterCompany/groupOverview.vue")
			// 		},
			// 		{
			// 			path:'/enterCompany/organization',
			// 			name:'organization',
			// 			meta:{title:'组织架构'},
			// 			component:()=>import("@/views/enterCompany/organization.vue")
			// 		},
			// 		{
			// 			path:'/enterCompany/managementTeam',
			// 			name:'managementTeam',
			// 			meta:{title:'管理团队'},
			// 			component:()=>import("@/views/enterCompany/managementTeam.vue")
			// 		},
			// 		{
			// 			path:'/enterCompany/culture',
			// 			name:'culture',
			// 			meta:{title:'企业文化'},
			// 			component:()=>import("@/views/enterCompany/culture.vue")
			// 		},
			// 		{
			// 			path:'/enterCompany/history',
			// 			name:'history',
			// 			meta:{title:'发展历程'},
			// 			component:()=>import("@/views/enterCompany/history.vue")
			// 		},

			// 	]
			// },
			// {
			// 	path:'/humanResources/index',
			// 	name:'humanResources',
			// 	meta:{title:'人力资源'},
			// 	redirect:{name:"talents"},
			// 	component:()=>import("@/views/humanResources/index.vue"),
			// 	children:[
			// 		{
			// 			path:'/humanResources/talents',
			// 			name:'talents',
			// 			meta:{title:'人才理念'},
			// 			component:()=>import("@/views/humanResources/talents.vue"),
			// 		},
			// 		{
			// 			path:'/humanResources/development',
			// 			name:'development',
			// 			meta:{title:'职业发展'},
			// 			component:()=>import("@/views/humanResources/development.vue"),
			// 		},
			// 		{
			// 			path:'/humanResources/recruiting',
			// 			name:'recruiting',
			// 			meta:{title:'招贤纳士'},
			// 			component:()=>import("@/views/humanResources/recruiting.vue"),
			// 		},
			// 		{
			// 			path:'/humanResources/statement',
			// 			name:'statement',
			// 			meta:{title:'招聘声明'},
			// 			component:()=>import("@/views/humanResources/statement.vue"),
			// 		},
			// 	]

			// },
			// {
			// 	path:'/college/index',
			// 	name:'college',
			// 	meta:{title:'知码芯大学'},
			// 	component:()=>import("@/views/college/index.vue"),
			// },
			// {
			// 	path:'/college/subjectDetail',
			// 	name:'subjectDetail',
			// 	meta:{title:'课程详情'},
			// 	component:() =>import("@/views/college/subjectDetail.vue")
			// },
			// {
			// 	path:'/humanResources/positionDetail',
			// 	name:'positionDetail',
			// 	meta:{title:'职位详情'},
			// 	component:()=>import("@/views/humanResources/positionDetail.vue"),
			// },
			// {
			// 	path:'/aboutUs/index',
			// 	name:'aboutUs',
			// 	meta:{title:'关于我们'},
			// 	component:()=>import("@/views/aboutUs/index.vue"),
			// }
			


		],

	},
	{
		path: "*",
		redirect: '/404',
		hidden:true
	}
]

// router.routes = routes
const router= new Router({
	routes
})
// router.beforeEach((to,from,next) => {
// 	console.log(to.path)
// 	if(to.path) {
// 		console.log("111111111111111111111111111111")
// 		document.title = '知码芯科技-' + to.meta.title
// 		console.log(to)
// 		console.log(document.title)
// 	}
// 	next()
// })
router.afterEach((to, from) => {
	if(to.path) {
		setTimeout(()=>{
			window.document.title = '知码芯科技-' + to.meta.title
		})
	}
  })
export default router

