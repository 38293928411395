import Vue from 'vue'
import App from './App.vue'
import './assets/iconfont/iconfont.css'

import router from "./router/index.js"
import store from "./store/index.js"
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
ElementUI.Dialog.props.lockScroll.default = false
Vue.use(ElementUI);
import timelineCarousel from 'timeline-carousel'
Vue.use(timelineCarousel)
// import 'bootstrap'
// import $ from "jquery"
// import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'
//移动端调试插件
// import vConsole from 'vconsole'
// Vue.prototype.$vConsole = new vConsole()

// import axios from 'axios'
// Vue.prototype.axios = axios



import echarts from 'echarts';
Vue.prototype.$echarts = echarts;
// Vue.prototype.p2api = 'https://cms2api.seschip.com/p2api';
Vue.prototype.p2api = 'https://www.seschip.com/p2api';
Vue.config.productionTip = false
// Vue.config.baseUrl = ""
new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app')
