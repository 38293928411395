import Vue from "vue"
import Vuex from 'vuex'
Vue.use(Vuex)

const store = new Vuex.Store({
	state:{
		navIndex:'',
		navName:'',
		navPath:'',
		menu:'',
		menuList:null
	},
	mutations:{
		changeActiveNav:(state,data) => {
			state.navIndex = data.navIndex
			state.navName = data.navName
			state.navPath = data.navPath
			localStorage.setItem("navActive",JSON.stringify({
				activeIndex:data.navIndex,
				activeName:data.navName,
				activePath:data.navPath
			}))
		},
		chooseMenu:(state,data) => {
			state.menu = data
			localStorage.setItem("menu",JSON.stringify(data))
		},
		setMenuList:(state,data) => {
			state.menuList = data
			localStorage.setItem("menuList",JSON.stringify(data))
		}
	}
	
}) 

export default store